






























































































































































import Vue from 'vue'
import { getCategoryList, getStoreListWithFilters, setStoreStatus, addStore as submitADD, editStore } from '@/api/store'
import { ElForm } from 'element-ui/types/form'
import { getOssUrl } from '@/api/common'
import { getUploadFileUrl } from '@/api/common'
import { adminToken } from '@/libs/local-store'
import { regionData } from 'element-china-area-data'
import { getHotelList } from '@/api/hotel'
import { getAdminInfo } from '@/api/admin'

export default Vue.extend({
	data() {
		return {
			labelPosition: 'right',
			statusList: [
				{ id: 0, name: '下架' },
				{ id: 1, name: '上架' },
			],
			searchForm: {
				name: '',
				status: '',
				store_category_id: '',
			},
			page: 1,
			size: 10,
			table_state: {
				loading: true,
			},
			total: 0,
			table_data: [] as any,

			baseUrl: '',
			store_category_list: [] as any,
			editId: '',
			store_submit_form_title: '编辑入驻商户',
			showDialogStore: false,
			store_submit_form: {
				seller_name: '',
				mobile: '',
				discount: 100,
				commission_rate: 0,
				business_license: '',
				area_code: [] as any,
				id_card_image1: '',
				id_card_image2: '',
				id_card_image: [] as any,
				hotel_id: '',
				area_info: [] as any,
				hotel_name: '',
				store_category_id: '',
				other_image: '',
			},
			store_submit_form_rules: {
				seller_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				store_category_id: [{ required: true, message: '请选择经营类别', trigger: 'change' }],
				mobile: [
					{ required: true, message: '联系电话必填', trigger: 'blur' },
					{ pattern: /^1[3456789]\d{9}$/, message: '联系电话格式不正确', trigger: 'blur' },
				],
				area_code: [{ required: true, message: '请选择申请区域', trigger: 'change' }],
				other_image: [
					{
						validator: (rule, value, callback) => {
							// if((this as any).store_submit_form.other_image )
							if ((this as any).store_submit_form.store_category_id == '1') {
								if ((this as any).store_submit_form.other_image == '') {
									callback(new Error('请上传证件'))
								}
							}
							callback()
						},
						trigger: 'change',
					},
				],
				business_license: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
				hotel_id: [{ required: true, message: '请选择绑定酒店', trigger: 'change' }],
				refuse_info: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }],
				id_card_image: [
					{
						validator: (rule, value, callback) => {
							if ((this as any).store_submit_form.id_card_image1 == '' || (this as any).store_submit_form.id_card_image2 == '') {
								callback(new Error('上传完整身份证信息'))
							}
							callback()
						},
						trigger: 'blur',
					},
				],
			},
			fileList: [] as any,
			HotelList: [] as any,
			admin_id: '' as any,
			regionData,
			TOKEN: '',
			getUploadFileUrl: '',
			hotel_ids: [],
		}
	},
	created() {
		this.TOKEN = adminToken.get()
		this.getUploadFileUrl = getUploadFileUrl()
		getOssUrl().then((res) => {
			this.baseUrl = res.data.domain
		})
		getCategoryList().then((res) => {
			if (res.code == 0) {
				this.store_category_list = res.data as any
			}
		})
		getHotelList().then((res) => {
			if (res.code == 0) {
				this.HotelList = res.data.rows
			}
		})
		getAdminInfo().then((res) => {
			if (res.code == 0) {
				this.admin_id = (res.data as any).id
				this.hotel_ids = (res.data as any).hotel_ids
				this.getStoreListWithFilters()
			}
		})

		// console.log('区域信息', this.regionData)
	},
	computed: {
		returnTitle() {
			if (this.store_submit_form.store_category_id == '1') {
				return '食品经营许可证/餐饮经营许可证'
			} else {
				return '其他'
			}
		},
	},
	methods: {
		getStoreListWithFiltersChange() {
			this.page = 1
			this.getStoreListWithFilters()
		},
		returnstore_category_name(id) {
			let find = this.store_category_list.find((item) => {
				return item.id == id
			})
			if (find) {
				return find.name
			}
			return ''
		},
		changeHotelValue(event) {
			let find = this.HotelList.find((item) => {
				return item.id == event
			})
			if (find) {
				this.store_submit_form.hotel_name = find.name
			}
		},
		// 改变申请区域值
		changeAreaAdcode(value) {
			this.store_submit_form.area_info = value
				.map((val) => {
					let selectedItem = this.findItemById(this.regionData, val)
					return selectedItem ? { value: selectedItem.value, label: selectedItem.label } : null
				})
				.filter((item) => item) // 过滤掉未找到的项
		},
		findItemById(options, value) {
			for (let option of options) {
				if (option.value === value) {
					return option
				}
				if (option.children && option.children.length) {
					let result = this.findItemById(option.children, value)
					if (result) return result
				}
			}
			return null
		},
		//  获取商户列表
		getStoreListWithFilters() {
			this.table_state.loading = true
			getStoreListWithFilters({
				...this.searchForm,
				page: this.page,
				size: this.size,
				examine_status: '1',
				hotel_ids: this.hotel_ids,
				status: this.searchForm.status + '',
			})
				.then((res) => {
					if (res.code == 0) {
						// console.log(res.data)

						if (res.data.data instanceof Array) {
							this.table_data = res.data.data.map((item) => {
								return {
									...item,
									business_hours: JSON.parse(item.business_hours),
								}
							})
							this.total = res.data.total
						} else {
							this.table_data = []
							this.total = 0
						}
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		// 商品上下架
		changeStaus(event, item) {
			// 0 未完善  1 完善
			if (item.is_perfect == 0) {
				this.$message({
					type: 'info',
					message: '请先完善商铺信息',
				})
				return
			}

			let changeStatus = event ? 1 : 0
			let data = {
				status: changeStatus,
				store_id: item.id,
			}
			let infoOpen = '确定要上架用该店铺吗？'
			let infoStop = '确定要下架该店铺吗？'
			let info = event ? infoOpen : infoStop
			this.$confirm(info, '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.table_state.loading = true
					setStoreStatus(data)
						.then((res) => {
							if (res.code == 0) {
								let findIndex = this.table_data.findIndex((itemf) => {
									return (itemf as any).id == item.id
								})
								if (findIndex !== -1) {
									this.$set(this.table_data[findIndex], 'status', changeStatus)
								}
								this.$message({
									type: 'success',
									message: res.data as unknown as string,
								})
							}
						})
						.catch((res) => {
							this.$message({
								type: 'info',
								message: res.data,
							})
						})
						.finally(() => {
							this.table_state.loading = false
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消操作`,
					})
				})
		},
		// 修改最大值
		changeLimit(data) {
			this.page = 1
			this.size = data
			this.getStoreListWithFilters()
		},
		// 修改页数
		changePages(data) {
			this.page = data
			this.getStoreListWithFilters()
		},
		editStore(item) {
			// eslint-disable-next-line no-undef
			let data = (window as any).structuredClone(item)
			// data.area_code = JSON.parse(data.area_code)
			// let imgarr = item.id_card_image)

			if (data.area_code.length == 1) {
				let arr: string[] = []
				data.area_info.reduce((resultArr: any[], item: string) => {
					let find = resultArr.find((itemarr) => {
						return itemarr.label == item
					})
					if (find) {
						arr.push(find.value)
					}
					return find.children
				}, this.regionData)
				data.area_code = arr
			}

			data.discount = data.discount / 10
			data.id_card_image1 = item.id_card_image ? this.baseUrl + decodeURIComponent(item.id_card_image[0]) : ''
			data.id_card_image2 = item.id_card_image ? this.baseUrl + decodeURIComponent(item.id_card_image[1]) : ''
			data.business_license = this.baseUrl + data.business_license
			data.other_image = data.other_image == '' ? '' : this.baseUrl + data.other_image
			data.hotel_id = Number(data.hotel_id)

			// console.log('editData', data)

			this.store_submit_form = data

			this.showDialogStore = true
		},
		submitMethod2() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					// eslint-disable-next-line no-undef
					let data: { [key: string]: any } = {
						...this.store_submit_form,
						admin_id: this.admin_id,
					}

					let base_url_length = this.baseUrl.split('').length
					if (data.id_card_image1.indexOf(this.baseUrl) !== -1) {
						data.id_card_image1 = data.id_card_image1.slice(base_url_length)
					}
					if (data.id_card_image2.indexOf(this.baseUrl) !== -1) {
						data.id_card_image2 = data.id_card_image2.slice(base_url_length)
					}
					if (data.business_license.indexOf(this.baseUrl) !== -1) {
						data.business_license = data.business_license.slice(base_url_length)
					}
					if (data.other_image.indexOf(this.baseUrl) !== -1) {
						data.other_image = data.other_image.slice(base_url_length)
					}

					// data.area_code = JSON.stringify(data.area_code)

					data.area_info
					let area_info_arr = [] as any

					for (const element of data.area_info) {
						if (typeof element === 'object') {
							area_info_arr.push(element.label)
						} else {
							area_info_arr.push(element)
						}
					}
					data.area_info = area_info_arr
					// if (data.area_info instanceof Array) {
					// 	data.area_info = data.area_info.map((item) => item.label)
					// }
					data.id_card_image = [data.id_card_image1, data.id_card_image2]
					data.discount = data.discount * 10
					delete data['id_card_image1']
					delete data['id_card_image2']
					// console.log('uploaddata', data)

					editStore(data)
						.then((res) => {
							if (res.code == 0) {
								this.showDialogStore = false
								this.$message({
									type: 'success',
									message: (res as any).data,
								})
								this.getStoreListWithFilters()
								this.$nextTick(() => {
									;(this.$refs['store_form'] as ElForm).resetFields()
								})
							}
						})
						.catch((err) => {
							this.$message({
								type: 'info',
								message: '添加失败',
							})
						})
				} else {
					return false
				}
			})
		},
		// 关闭DIA
		closeDialog() {
			this.store_submit_form = {
				area_info: [],
				hotel_id: '',
				id_card_image1: '',
				id_card_image2: '',
				id_card_image: '',
				area_code: [],
				seller_name: '',
				mobile: '',
				discount: 100,
				commission_rate: 0,
				business_license: '',
				hotel_name: '',
				store_category_id: '',
				other_image: '',
			}
		},
		que() {
			this.searchForm = {
				name: '',
				status: '',
				store_category_id: '',
			}
			this.page = 1
			this.size = 10
			this.getStoreListWithFilters()
		},
	},
})
