

































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getUserList } from '@/api/user'
import SelectUserRegion from '@/components/select/select-user-region.vue'
import { cloneDeep } from 'lodash'
export default Vue.extend({
	components: { SelectUserRegion },
	inject: ['oss56'],
	data() {
		return {
			...useTable({
				name: '户型标签',
				formdata: {
					/** ID */
					id: 0,
					/** 昵称 */
					name: '',
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					}
					console.log(n_query)

					return getUserList(n_query).then((res) => res.data)
				},
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
