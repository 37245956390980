











































import Vue, { PropType, CreateElement, VNode } from 'vue'
import { mapState } from 'vuex'
import menus, { Menu } from '@/common/side-menu'
import Icon from '@/components/icon/icon.vue'
import { forEach } from 'lodash'

const MenuIcon = Vue.extend({
	props: {
		render: [Function, String] as PropType<((h: CreateElement) => VNode) | string>,
	},
	render(h) {
		return typeof this.render == 'string' ? h(Icon, { props: { name: this.render, size: 18 } }) : this.render(h)
	},
})

export default Vue.extend({
	props: {
		/** 同main页面的layout属性 */
		layout: Object,
		/** 小屏模式 */
		miniMode: {
			type: Boolean,
			default: false,
		},
	},
	components: { MenuIcon },
	data() {
		return {
			menus,
		}
	},
	computed: {
		...mapState<any>('admin', {
			admin_info: (state): any => state.info,
		}),
		defaultActive() {
			let menuIndex: number = this.menus
				.filter((it) => !it.children && it.route)
				.findIndex((it) => {
					let match = this.$router.match(typeof it.route == 'string' ? { name: it.route } : it)
					return match.name == this.$route.name
				})
			if (menuIndex != -1) return `${menuIndex}`

			for (let i = 0; i < this.menus.length; i++) {
				if (!this.menus[i].children) continue
				// @ts-ignore
				for (let j = 0; j < this.menus[i].children.length; j++) {
					// @ts-ignore
					let sub = this.menus[i].children[j]
					let match = this.$router.match(typeof sub.route == 'string' ? { name: sub.route } : sub)
					if (match.name == this.$route.name) return `${i}-${j}`
				}
			}

			return ''
		},
	},
	methods: {
		onMenuSelect(index) {
			let paths = index.split('-')
			let menu = this.menus[paths[0]] as Menu | undefined
			if (menu && menu.children && paths[1]) menu = menu.children[paths[1]]
			if (!menu) return

			if (menu.route) {
				let route = typeof menu.route == 'string' ? { name: menu.route } : menu.route
				if (menu.blank) window.open(this.$router.resolve(route).href)
				else this.$router.push(route).catch((e) => {})
			} else if (menu.link) {
				if (menu.blank) window.open(menu.link)
				else window.location.href = menu.link
			}
		},
		mergeMenu() {
			let admin_info = this.$store.state.admin.info
			if (admin_info.id == 1) return //超级管理员不处理权限

			let default_routes = ['home'] //前端默认显示的
			let result_meuns = [] //菜单数组合并后的结果
			let menus = this.menus
			let role_menus = (admin_info?.role?.menus ?? []).map((res) => res.key)
			menus.forEach((res: any) => {
				if (res?.children) {
					let children = res.children
					let r_children = []
					children.forEach((r: any) => {
						if (default_routes.indexOf(r?.route) >= 0) return r_children.push(r as never)
						if (role_menus.indexOf(r?.route) >= 0) return r_children.push(r as never)
					})
					if (r_children.length) {
						res.children = r_children
						return result_meuns.push(res as never)
					}
				} else {
					if (default_routes.indexOf(res?.route) >= 0) return result_meuns.push(res as never)
					if (role_menus.indexOf(res?.route) >= 0) return result_meuns.push(res as never)
				}
			})
			this.menus = result_meuns
		},
	},
	created() {
		this.mergeMenu()
	},
})
