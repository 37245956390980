























































import Vue from 'vue'
import { getCategoryListData, addCategory, updateCategory, deleteCategory } from '@/api/store'
import { ElForm } from 'element-ui/types/form'
import { adminToken } from '@/libs/local-store'

export default Vue.extend({
	data() {
		return {
			labelPosition: 'right',
			searchForm: {
				name: '',
			},
			page: 1,
			size: 10,
			table_state: {
				loading: true,
			},
			total: 0,
			table_data: [] as any,

			editId: '',
			store_submit_form_title: '编辑经营类别',
			showDialogStore: false,
			store_submit_form: {
				name: '',
				weigh: 0,
				id: '',
			},
			store_submit_form_rules: {
				name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
			},
			TOKEN: '',
		}
	},
	created() {
		this.TOKEN = adminToken.get()
		this.getCategoryListData()
	},
	methods: {
		getCategoryListDataChange() {
			this.page = 1
			this.getCategoryListData()
		},
		//  获取列表
		getCategoryListData() {
			this.table_state.loading = true
			getCategoryListData({
				...this.searchForm,
				page: this.page,
				size: this.size,
			})
				.then((res) => {
					if (res.code == 0) {
						if (res.data.list instanceof Array) {
							this.table_data = res.data.list
							this.total = res.data.total
						} else {
							this.table_data = []
							this.total = 0
						}
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		// 修改最大值
		changeLimit(data) {
			this.page = 1
			this.size = data
			this.getCategoryListData()
		},
		// 修改页数
		changePages(data) {
			this.page = data
			this.getCategoryListData()
		},
		editStore(item) {
			this.store_submit_form_title = '编辑经营类别'
			this.store_submit_form.id = item.id
			this.store_submit_form.weigh = item.weigh
			this.store_submit_form.name = item.name
			this.showDialogStore = true
		},
		addStore(item) {
			this.store_submit_form_title = '新增经营类别'
			this.store_submit_form = {
				id: '',
				weigh: 0,
				name: '',
			}
			this.showDialogStore = true
		},
		submitMethod2() {
			;(this.$refs['store_form'] as ElForm).validate((valid) => {
				if (valid) {
					let data = { ...this.store_submit_form }
					if (data.id !== '') {
						updateCategory(data)
							.then((res) => {
								if (res.code == 0) {
									this.showDialogStore = false
									this.$message({
										type: 'success',
										message: (res as any).data,
									})
									this.getCategoryListData()
									this.$nextTick(() => {
										;(this.$refs['store_form'] as ElForm).resetFields()
									})
								}
							})
							.catch((err) => {
								this.$message({
									type: 'info',
									message: '编辑失败',
								})
							})
					} else {
						addCategory(data)
							.then((res) => {
								if (res.code == 0) {
									this.showDialogStore = false
									this.$message({
										type: 'success',
										message: (res as any).data,
									})
									this.getCategoryListData()
									this.$nextTick(() => {
										;(this.$refs['store_form'] as ElForm).resetFields()
									})
								}
							})
							.catch((err) => {
								this.$message({
									type: 'info',
									message: '添加失败',
								})
							})
					}
				} else {
					return false
				}
			})
		},
		// 关闭DIA
		closeDialog() {
			this.store_submit_form = {
				name: '',
				weigh: 0,
				id: '',
			}
		},
		que() {
			this.searchForm = {
				name: '',
			}
			this.page = 1
			this.size = 10
			this.getCategoryListData()
		},
		updateCategorym(item) {
			this.$confirm('确定删除改类别吗', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.table_state.loading = true
					deleteCategory({
						id: item.id,
					})
						.then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: res.data as any,
								})
								this.getCategoryListData()
							}
						})
						.finally(() => {
							this.table_state.loading = false
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: `已取消操作`,
					})
				})
		},
	},
})
