



















/**
 * @slot left 左侧插槽，一般放新增、导出等功能按钮，小屏下会自动侧独占一行，内容过长会左右滚动
 * @slot before-right 右侧工具栏前置插槽，一般放额外筛选项
 */
import Vue from 'vue'
import store from '@/store'
export default Vue.extend({
	props: {
		/** 搜索词，支持.sync双向绑定 */
		isSearch: {
			type: Boolean,
			default: false,
		},
		searchWord: String,
		searchPlaceholder: {
			type: String,
			default: '搜索名称',
		},
		size: {
			type: String,
			validator: (v) => ['mini', 'small', 'medium'].includes(v as string),
			default: store.state.app.device_info.type == 'pc' ? 'medium' : 'mini',
		},
		searchWidth: {
			type: String,
			default: '280px',
		},
	},
	// emits:[
	// 	search(search_word), // 搜索，search_word搜索词
	// 	refresh(), //刷新
	// ],
	watch: {
		searchWord: {
			immediate: true,
			handler(val) {
				if (val != this.my_search_word) this.my_search_word = val
			},
		},
	},
	data() {
		return {
			my_search_word: '',
		}
	},
	methods: {},
	mounted() {},
})
