import request, { ResponsePageableData } from '@/libs/request'

//获取活动列表
export const getActivityList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Activity/getActivityList', data })

//编辑活动
export const editActivity = (data?) => request<ResponsePageableData>({ url: '/adminapi/Activity/editActivity', data })

//删除活动
export const delActivity = (data?) => request<ResponsePageableData>({ url: '/adminapi/Activity/delActivity', data })

// 天天特惠不参加满减
export const getActivityConfig = (data?) => request<ResponsePageableData>({ url: '/adminapi/Activity/getActivityConfig', data })

