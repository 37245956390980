








































































































import Vue from 'vue'
import { getOrderListWithFilters } from '@/api/store'
import { getAdminInfo } from '@/api/admin'
export default Vue.extend({
	data() {
		return {
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit('pick', [start, end])
						},
					},
				],
			},
			// 订单状态:0=已失败,1=生成订单,2=已完成
			orderstatusList: ['已失败', '生成订单', '已完成'],
			value2: '',
			numInfoAll: {
				stroeNums: 0,
				orderNums: 0,
				priceNums: 0,
				commission_rateNums: 0,
			},
			// :0=已失败,1=生成订单,2=已完成
			statusList: [
				{ id: 1, name: '已完成' },
				{ id: 2, name: '已退款' },
			],
			searchForm: {
				store_name: '',
				total_status: '',
				store_category_id: '',
				create_time: [],
				order_sn: '',
			},
			page: 1,
			size: 10,
			table_data: [],
			total: 0,
			table_state: {
				loading: true,
			},
			hotel_ids: [],
		}
	},
	created() {
		getAdminInfo().then((res) => {
			if (res.code == 0) {
				this.hotel_ids = (res.data as any).hotel_ids
				this.getOrderListWithFilters()
			}
		})
	},
	methods: {
		resetNum() {
			this.page = 1
			this.getOrderListWithFilters()
		},
		getNumsInfo() {
			// 平台商户总数
			this.numInfoAll.stroeNums = 1
			// 订单总数
			this.numInfoAll.orderNums = 1
			// 交易总额
			this.numInfoAll.priceNums = 1
			// 总佣金
			this.numInfoAll.commission_rateNums = 1
		},
		getOrderListWithFilters() {
			let dataArr = this.searchForm.create_time
			let start_date = ''
			let end_date = ''

			if (dataArr instanceof Array) {
				start_date = dataArr[0] || ''
				end_date = dataArr[1] || ''
			}

			// 已完成 2 0  已退款 2 7

			let data: { [key: string]: any } = {
				...this.searchForm,
				page: this.page,
				size: this.size,
				start_date,
				end_date,
				hotel_ids: this.hotel_ids,
			}

			if (data.total_status == 1) {
				data.order_status = 2
				data.refund_status = 0
			} else if (data.total_status == 2) {
				data.order_status = 2
				data.refund_status = 7
			} else {
				data.order_status = ''
				data.refund_status = ''
			}

			delete data.total_status

			getOrderListWithFilters(data)
				.then((res) => {
					if (res.code == 0) {
						this.table_data = res.data.data
						this.total = res.data.total

						// 平台商户总数
						this.numInfoAll.stroeNums = res.data.store_total
						// 订单总数
						this.numInfoAll.orderNums = res.data.order_total
						// 交易总额
						this.numInfoAll.priceNums = res.data.amount_total / 100
						// 总佣金
						this.numInfoAll.commission_rateNums = res.data.commission_price_totle / 100
					}
				})
				.finally(() => {
					this.table_state.loading = false
				})
		},
		que() {
			this.searchForm = {
				store_name: '',
				total_status: '',
				store_category_id: '',
				create_time: [],
				order_sn: '',
			}
			this.page = 1
			this.size = 10
			this.getOrderListWithFilters()
		},
		changeLimit(data) {
			this.page = 1
			this.size = data
			this.getOrderListWithFilters()
		},
		changePages(data) {
			this.page = data
			this.getOrderListWithFilters()
		},
	},
})
