import request, { ResponsePageableData } from '@/libs/request/requestOta'

let baseURL = process.env.VUE_APP_OTA_BASE_URL

/** 设置模型字段 */
// 订单列表
export const getOrderList = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/order', data, method: 'GET' })

// 失败订单列表
export const getOrderListFailed = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/orderfailed', data, method: 'GET' })

// 重试失败订单
export const putOrderListFailed = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/orderfailed', data, method: 'PUT' })

// 完成未处理订单
export const putOrderList = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/order', data, method: 'PUT' })

// 取消成功订单
export const cancleOrderList = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/order', data, method: 'PUT' })

// 酒店映射表列表
export const getHotelsList = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotel', data, method: 'GET' })

// 酒店房型列表
export const getPmsroomlist = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/pmsroomlist/', data, method: 'GET' })

// 新增酒店映射表
export const postXcpmsHotel = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotel', data, method: 'POST' })

// 编辑酒店映射表
export const putXcpmsHotel = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotel', data, method: 'PUT' })

// 删除酒店映射表
export const deleteXcpmsHotel = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotel', data, method: 'DELETE' })

// 房型映射表
export const gethotelroomsList = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotelroom', data, method: 'GET' })

// 新增房型映射表
export const postXcpmshotelroom = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotelroom', data, method: 'POST' })

// 编辑房型映射表
export const putXcpmshotelroom = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotelroom', data, method: 'PUT' })

// 删除房型映射表
export const deleteXcpmshotelroom = (data?) => request<ResponsePageableData>({ baseURL, url: '/v1/xcpms/hotelroom', data, method: 'DELETE' })
