import request, { RequestConfig, ResponsePageableData } from '@/libs/request'

/** 上传图片 */
export const uploadImage = (data?, onUploadProgress?) => request<ResponsePageableData>({ url: '/commonapi/Upload/uploadImage', data, onUploadProgress })
export const getUploadImageUrl = () => process.env.VUE_APP_API_BASE_URL + '/commonapi/Upload/uploadImage'

export const getUploadFileUrl = () => process.env.VUE_APP_API_BASE_URL + '/commonapi/Upload/uploadFile'

export const setModelField = (data?) => request<ResponsePageableData>({ url: '/commonapi/Other/setModelField', data })

export const setHotelState = (data?) => request<ResponsePageableData>({ url: '/adminapi/Hotel/setHotelState', data })

/** 获取图片域名 */
export const getOssUrl = (data?) => request<ResponsePageableData>({ url: '/userapi/other/getOssUrl', data })
