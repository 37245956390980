




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import SelectHotelOrderState from '@/components/select/select-hotel-order-state.vue'
import SelectRefundStatus from '@/components/select/select-refund-status.vue'
import SelectRemarkState from '@/components/select/select-remark-state.vue'
import SelectHoteltype from '@/components/select/select-order-type .vue'
import SelectApitype from '@/components/select/select-api-type.vue'
import SelectPlatform from '@/components/select/select-platform.vue'
import SelectOrderMode from '@/components/select/select-order-mode.vue'
import SelectHotel from '@/components/select/select-hotel.vue'
import SelectHouse from '@/components/select/select-house.vue' //房间选择器

import tableHeader from '@/components/table/tableHeader.vue'
import { useTable } from '@/components/table/table-hook'
import {
	getOrderHotelList,
	editOrderHotel,
	getdataList,
	cancelOrder,
	checkOrder,
	getOrderRefundInfo,
	refundSubmit,
	getRoomTypeList,
	moveOrderToMicWin,
	getOrderHotelStays,
} from '@/api/hotel-order'
import { refundOrder } from '@/api/hotelOrder'
import { getTreeName } from '@/libs/vue-tools/index'
import { cloneDeep } from 'lodash'
import { createLogger, mapState } from 'vuex'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { export_json_to_excelPro } from '@/vendor/Export2ExcelPro'
import { login } from '@/api/admin'
import order from '@/store/modules/order'
import { Form } from 'element-ui'
export default Vue.extend({
	components: {
		SelectHotelOrderState,
		SelectHouse,
		SelectHotel,
		SelectHoteltype,
		SelectRemarkState,
		SelectOrderMode,
		SelectApitype,
		SelectRefundStatus,
		SelectPlatform,
		tableHeader,
	},
	data() {
		const validateNumber = (rule: any, value: any, callback: any) => {
			if (value.type == 'room_no') {
				if (value.value.join(',').length > 0) {
					callback()
				} else {
					callback(new Error('请输入房间号'))
				}
			}
			if (value.type == 'stay_ids') {
				if (value.value.length > 0) {
					callback()
				} else {
					callback(new Error('请选择房间号'))
				}
			}
		}
		return {
			refundDialogBaseInfo: {
				out_trade_no: '', // 订单号
				user_names: [], // 入住人姓名(用户姓名)
				stay_time: '', // 入住时间
				pay_type: 0, // 支付类型 1.微信 2.支付宝 3.H5 4.其他
				platform_id: 0, // row.platform_id  == 1 '万澳会' == 2 '同行' == 3  '商旅部' == 4 '携程' == 5 '畅游西安' == 6 '小程序' == 7 '西旅小程序' == 8'无'
			},
			tableKey: 0 as number, // 初始化 key
			// 固定表头
			tableSize: 'small',
			tableHeight: 0,
			// 列表导出
			exportLoading: false,
			// 导出进度条
			exportProgress: false,
			exportPercentage: 0,
			startInput: 1,
			startMinValue: 0,
			startmaxValue: 1,
			endInput: 1,
			endMinValue: 0,
			endmaxValue: 1,
			// 查询设置展开
			isIndeterminate: false,
			isExpend: true,
			checkAll: true, // 全选复选框的值
			resectChecked: [] as any[],
			checked: [] as any[],
			instalList: [
				{ name: '序号', key: 'xh' },
				{ name: '订单号', key: 'ddh' },
				{ name: '用户姓名', key: 'xm' },
				{ name: '入住人手机号', key: 'rzsjh' },
				{ name: '会员手机号', key: 'hysj' },
				{ name: '预定房间数量', key: 'ydfjsl' },
				{ name: '间夜数', key: 'yjs' },
				{ name: '早餐数', key: 'zcs' },
				{ name: '微信/支付宝', key: 'zfbwx' },
				{ name: '余额支付', key: 'zfye' },
				{ name: '入住日期', key: 'rzrq' },
				{ name: '离店日期', key: 'ldrq' },
				{ name: '到店时间', key: 'ddsj' },
				{ name: '完成时间', key: 'wcsj' },
				{ name: '取消时间', key: 'qxsj' },
				{ name: '订单类型', key: 'ddlx' },
				{ name: '接口平台', key: 'jkpt' },
				{ name: '市场类型', key: 'sclx' },
				{ name: '赠送积分', key: 'zsjf' },
				{ name: '支付时间', key: 'zfsj' },
				{ name: '订单状态', key: 'ddzt' },
				{ name: '点评状态', key: 'dpzt' },
			],
			// 查询设置

			refundPlatform_id: '' as any,
			region: '' as any,
			dialogFormVisible_transfer: false,
			disabled: false,
			refundDialog: false,
			comfirmRefundDialog: false,
			dialogVisible: false,
			is_export: 0,
			sum_data: [] as any,
			order_data: [] as any,
			export_data: [] as any,
			roomNumber: [] as any,
			hotel_data: [] as any,
			comfirmRefundTableData: [] as any,
			refundForm: {
				id: '',
				number: null as any,
				room_no: '' as any,
				stay_ids: [] as any,
				time_flag: '' as any,
				break_no: '' as any,
				refund_pay_fee: '' as any,
				refund_balance: '' as any,
				refund_integral: '' as any,
				refund_saler_balance: '' as any,
				breakfast_refund_pay_fee: '' as any, // 早餐退款金额
				breakfast_refund_saler_balance: '' as any, // 返还佣金
				breakfast_refund_balance: '0' as any, //早餐退款金额
				breakfast_refund_num: '' as any,
				breakfast_refund_num_show: '' as any,
				time_romm: [] as any,
				room_number: '' as any,
				refund_price: '' as any,
			},
			all_refund_total_balance: '' as any, // 返还总余额
			refund_total_pay_fee: '' as any,
			refund_total_integral: '' as any,
			refund_total_balance: '0' as any,
			refund_total_price: '' as any,
			refund_total_saler_balance: '' as any,
			breakfast_refund_total_pay_fee: '' as any,
			breakfast_refund_total_balance: '0' as any,
			breakfast_refund_total_price: '' as any,
			breakfast_refund_balance: '' as any,
			order_id: '' as any,
			order_state: '' as any,
			Order_id: '' as any,
			order_roomtype: '' as any,
			hotel_ode: '' as any,
			roomStaysInfo: [] as any,
			//平台支付总金额
			// sum_integral: null, // 支付总积分
			// sum_balance: null, //支付总余额
			editOrderHotel,
			...useTable({
				name: '订单',
				formdata: {
					id: 0,
					room_id: undefined,
				},

				query: {},

				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_pay_time'] = n_query['datetimes'][0]
						n_query['end_pay_time'] = n_query['datetimes'][1]
					}
					if (n_query['staytimes']) {
						n_query['start_stay_time'] = n_query['staytimes'][0]
						n_query['end_stay_time'] = n_query['staytimes'][1]
					}
					if (n_query['completetimes']) {
						n_query['start_complete_time'] = n_query['completetimes'][0]
						n_query['end_complete_time'] = n_query['completetimes'][1]
					}
					if (n_query['canceltimes']) {
						n_query['start_cancel_time'] = n_query['canceltimes'][0]
						n_query['end_cancel_time'] = n_query['canceltimes'][1]
					}
					return getOrderHotelList(n_query).then((res) => res.data)
				},
				// canCelData: (row) => cancelOrder({ id: row.id, name: row.out_trade_no }),
			}),

			rules: {
				number: [{ required: true, message: '请选择房间数量', trigger: 'blur' }],
				room_number: [{ required: true, validator: validateNumber }],
				time_flag: [{ required: true, message: '请选择是否包含当晚房费', trigger: ['change'] }],
			},
		}
	},
	created() {
		this.resectChecked = cloneDeep(this.instalList.map((item) => item.key))
		const listCheckedAll = sessionStorage.getItem('listChecked')
		if (listCheckedAll) {
			this.checked = JSON.parse(listCheckedAll)
		} else {
			this.checked = this.instalList.map((item) => item.key)
		}
		if (this.checked.length == this.instalList.length) {
			this.checkAll = true
			this.isIndeterminate = false
		} else {
			this.checkAll = false
			this.isIndeterminate = true
		}
	},
	mounted() {
		this.tableGetData()
			.then(() => {
				// 获取数据完成后调用计算表格高度
				this.calculateTableHeight()
			})
			.catch((error) => {
				console.error('数据加载失败:', error)
			})
		window.addEventListener('resize', this.calculateTableHeight)
	},
	destroyed() {
		window.removeEventListener('resize', this.calculateTableHeight)
	},
	methods: {
		// 请求数据
		fetchData() {
			this.table_query.index = 1
			this.tableGetData()
		},

		selectOption(option) {
			this.tableSize = option
		},
		// 列表导出起始页
		handleStartInput(value) {
			value = parseInt(value, 10)
			// if (isNaN(value)) {
			// 	value = 1
			// }
			// if (value < 1) {
			// 	value = 1
			// }
			// if (value > this.endInput) {
			// 	value = this.endInput
			// }
			this.startInput = value
		},
		handleEndInput(value) {
			value = parseInt(value, 10)
			// if (isNaN(value)) {
			// 	value = 1
			// }
			// if (value < 1) {
			// 	value = 1
			// }
			if (value > this.endmaxValue) {
				value = this.endmaxValue
			}
			this.endInput = value
		},
		// 调用 getData 获取数据并计算高度
		// 计算表格的高度
		calculateTableHeight() {
			this.$nextTick(() => {
				// 获取窗口或容器的高度 (例如，获取 body 或某个父容器的高度)
				const containerHeight = window.innerHeight // 获取视口高度，也可以替换为其他容器高度

				// 正确的条件判断，先判断 isExpend 决定 600 或 550，再做减法运算
				this.tableHeight = containerHeight - (this.isExpend ? 580 : 680)
				// 强制重新渲染表格，更新 key
				this.tableKey++ // 增加 key 来触发重新渲染
			})
		},
		// 搜索设置展开
		toggleExpand() {
			this.isExpend = !this.isExpend
			this.calculateTableHeight()
		},
		// 更新全选框的状态
		updateCheckAll() {
			if (this.checked.length === 0) {
				this.checkAll = false // 如果没有选中任何项，全选框应为未选中
			} else if (this.checked.length === this.instalList.length) {
				this.checkAll = true // 如果选中所有项，全选框应为选中
			} else {
				this.checkAll = false // 如果部分选中，全选框应为未选中
			}
			this.isIndeterminate = this.checked.length > 0 && this.checked.length < this.instalList.length
		},

		// 处理全选框改变事件
		handleCheckAllChange() {
			const allItems = this.instalList.map((item) => item.key)

			if (this.checkAll) {
				// 全选时，选中所有项
				this.checked = [...allItems]
			} else {
				// 取消全选时，清空选中项
				this.checked = []
			}
			this.isIndeterminate = false
			this.tableKey++
			sessionStorage.setItem('listChecked', JSON.stringify(this.checked))
		},

		// 处理单个复选框的改变事件
		handleCheckedInstal() {
			this.updateCheckAll()
			this.tableKey++
			sessionStorage.setItem('listChecked', JSON.stringify(this.checked))
		},
		// 重置按钮
		handleReset() {
			this.checked = cloneDeep(this.resectChecked)
			this.checkAll = true
			this.isIndeterminate = false
			this.tableKey++
			sessionStorage.setItem('listChecked', JSON.stringify(this.checked))
		},
		// 搜索设置标记

		changeRoomNoType1(event) {
			this.refundForm.room_number = {
				type: 'room_no',
				value: event,
			}
		},
		changeRoomNo() {
			this.refundForm.room_no = ''
			this.refundForm.stay_ids.forEach((item: any) => {
				let finded = this.roomStaysInfo.find((it: any) => {
					return item == it.id
				})
				if (finded) {
					this.refundForm.room_no += (finded.room || '未入住房间') + ','
				}
			})
			console.log(this.refundForm)
		},
		changeRoomNoType2($event) {
			this.refundForm.room_number = {
				type: 'stay_ids',
				value: $event,
			}
			if ($event.length == 0) {
				this.refundForm.number == ''
			}
		},
		// 移除tag触发
		removeCheckedCitiesChange(event) {
			if (this.refundForm.stay_ids.length > 0) {
				this.handleCheckedCitiesChange(false, this.refundForm.stay_ids)
			} else {
				this.resetRefundInfo()
			}
		},
		// 退款选择
		handleCheckedCitiesChange(event, value) {
			console.log(event, value)

			this.refundForm.room_number = {
				type: 'stay_ids',
				value: value,
			}
			if (!event) {
			} else {
				return
			}
			let list = this.refundForm.stay_ids || []
			this.refundForm.number = list.length
			console.log(this.refundPlatform_id)

			if (this.refundPlatform_id == 1 || this.refundPlatform_id == 2 || this.refundPlatform_id == 3 || this.refundPlatform_id == 6) {
				this.changeRoomNo()
			}
			if (this.refundForm.number <= 0) {
				this.resetRefundInfo()
				return
			}
			;(this.$refs.refundFormRef as Form).validate((valid) => {
				if (valid) {
					checkOrder({
						id: this.refundForm.id,
						number: this.refundForm.number,
						time_flag: this.refundForm.time_flag,
						stay_ids: this.refundForm.stay_ids,
					})
						.then((res) => {
							if (res.code == 0) {
								this.refundForm.refund_balance = res.data.refund_balance
								this.refundForm.refund_integral = res.data.refund_integral
								this.refundForm.refund_pay_fee = res.data.refund_pay_fee
								this.refundForm.refund_saler_balance = res.data.refund_saler_balance
								this.refundForm.breakfast_refund_pay_fee = res.data.breakfast_refund_pay_fee
								this.refundForm.breakfast_refund_saler_balance = res.data.breakfast_refund_saler_balance
								this.refundForm.breakfast_refund_num_show = res.data.breakfast_refund_num + '份'
								this.refundForm.breakfast_refund_num = res.data.breakfast_refund_num
								this.refundForm.breakfast_refund_balance = res.data.breakfast_refund_balance
								this.refundForm.time_romm = res.data.refund_daily_price
								this.refundForm.refund_price = res.data.refund_price
							} else {
								this.$message.error(res.msg)
								return
							}
						})
						.catch(() => {
							this.clearRefundCheck()
						})
				}
			})
		},
		// 订单确认按钮事件
		Confirm() {
			this.region != '' ? (this.dialogFormVisible_transfer = false) : (this.dialogFormVisible_transfer = true)
			this.moveOrderToMicWin()
		},
		// 订单确认接口
		moveOrderToMicWin() {
			if (this.region != '') {
				moveOrderToMicWin({ id: this.Order_id, room_type_id: this.region }).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: '同步成功',
							type: 'success',
							onClose: () => {
								this.tableGetData()
							},
						})
						this.region = ''
						this.hotel_data = []
					}
				})
			} else {
				this.$message({
					message: '请选择房型',
					type: 'warning',
				})
			}
		},
		//获取房型列表
		getRoomTypeList(hotel_id) {
			this.hotel_data = []
			this.region = ''
			getRoomTypeList({ hotel_id }).then((res) => {
				this.hotel_data = res.data.rows
			})
		},
		// 同步订单按钮事件
		transfer(data) {
			this.getRoomTypeList(data.hotel_id)
			this.order_roomtype = JSON.parse(data.room_type_snapshot).name
			this.hotel_ode = JSON.parse(data.hotel_snapshot).name
			this.Order_id = data.id
			this.dialogFormVisible_transfer = true
		},
		addLeadingZero(number) {
			return number < 10 ? '0' + number : number
		},
		getTommorrow() {
			let date = new Date()
			let tomorrow = new Date(date.getTime() + 24 * 60 * 60 * 1000)
			var tomorrowYear = tomorrow.getFullYear() // 获取明天的年份
			var tomorrowMonth = tomorrow.getMonth() + 1 // 获取明天的月份（注意月份是从 0 开始计数的，所以需要加 1）
			var tomorrowDate = tomorrow.getDate() // 获取明天的日期
			var tomorrowFormatted = tomorrowYear + '-' + this.addLeadingZero(tomorrowMonth) + '-' + this.addLeadingZero(tomorrowDate) // 格式化为 'YYYY-MM-DD' 的日期字符串
			return tomorrowFormatted
		},
		// 退款
		refund(row) {
			this.roomStaysInfo = []
			this.refundDialogBaseInfo.pay_type = row.pay_type
			this.refundDialogBaseInfo.out_trade_no = row.out_trade_no
			this.refundDialogBaseInfo.stay_time = row.stay_time
			this.refundDialogBaseInfo.user_names = row.user_names
			this.refundDialogBaseInfo.platform_id = row.platform_id
			// 获取退款房间信息
			// 		<!-- row.platform_id  == 1 '万澳会' == 2 '同行' == 3  '商旅部' == 4 '携程' == 5 '畅游西安' == 6 '小程序' == 7 '西旅小程序' == 8'无'-->
			console.log(row.platform_id)
			if (row.platform_id == 1 || row.platform_id == 2 || row.platform_id == 3 || row.platform_id == 6) {
				getOrderHotelStays({ order_id: row.id }).then((res: any) => {
					let list = res.data
					this.roomStaysInfo = list
					// this.roomStaysInfo = list.filter((item: any) => {
					// 	return item.refund_status == 1
					// })
				})
			}
			this.refundForm.room_number = ''
			this.refundForm.id = row.id
			this.refundPlatform_id = row.platform_id

			let refundRoomNumber = row.room_number - row.refund_room_number
			this.roomNumber = []
			this.disabled = false
			// this.roomNumber = row.room_number
			if (refundRoomNumber > 0) {
				for (var i = 1; i <= refundRoomNumber; i++) {
					this.roomNumber.push(i)
				}
			}
			this.refundDialog = true
			if (this.$refs.refundFormRef) {
				;(this.$refs.refundFormRef as Form).resetFields()
			}
		},
		confirmRefund(row) {
			this.order_id = row.id
			this.order_state = row.state
			this.comfirmRefundDialog = true
			this.refundPlatform_id = row.platform_id

			getOrderRefundInfo({ id: this.order_id }).then((res) => {
				this.comfirmRefundTableData = res.data.order_refund_log.map((item) => {
					return {
						...(item as object),
						platform_id: res.data.platform_id,
						all_refund_total_saler_balance: res.data.all_refund_total_saler_balance,
						all_refund_total_balance: res.data.all_refund_total_balance,
					}
				})

				this.breakfast_refund_total_pay_fee = res.data.breakfast_refund_total_pay_fee
				this.breakfast_refund_total_balance = res.data.breakfast_refund_total_balance
				this.breakfast_refund_total_price = res.data.breakfast_refund_total_price
				this.refund_total_pay_fee = res.data.refund_total_pay_fee
				this.refund_total_integral = res.data.refund_total_integral
				this.refund_total_balance = res.data.refund_total_balance
				this.breakfast_refund_balance = res.data.breakfast_refund_balance
				this.refund_total_price = res.data.refund_total_price
				this.refund_total_saler_balance = res.data.all_refund_total_saler_balance
				this.all_refund_total_balance = res.data.all_refund_total_balance
			})
		},
		resetRefundInfo() {
			this.refundForm.refund_balance = 0
			this.refundForm.breakfast_refund_balance = 0
			this.refundForm.refund_integral = 0
			this.refundForm.refund_pay_fee = 0
			this.refundForm.refund_saler_balance = 0
			this.refundForm.breakfast_refund_pay_fee = 0
			this.refundForm.breakfast_refund_saler_balance = 0
			this.refundForm.breakfast_refund_num_show = ''
			this.refundForm.breakfast_refund_num = 0
			this.refundForm.refund_price = 0
			this.refundForm.time_romm = []
		},
		comfirmRefundSubmit() {
			this.$confirm('退款操作执行后将无法挽回，请确认退款信息和退款金额是否正确，确定执行退款操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			})
				.then(() => {
					refundSubmit({ id: this.order_id }).then((res) => {
						if (res.code == 0) {
							this.comfirmRefundDialog = false
							this.$message({
								type: 'success',
								message: '操作成功!',
								onClose: () => {
									this.tableGetData()
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		changRoomNumber() {
			if (this.refundForm.number <= 0) {
				this.resetRefundInfo()
				return
			}
			;(this.$refs.refundFormRef as Form).validate((valid) => {
				if (valid) {
					checkOrder({
						id: this.refundForm.id,
						number: this.refundForm.number,
						time_flag: this.refundForm.time_flag,
						stay_ids: this.refundForm.stay_ids,
					})
						.then((res) => {
							if (res.code == 0) {
								this.refundForm.refund_balance = res.data.refund_balance
								this.refundForm.refund_integral = res.data.refund_integral
								this.refundForm.refund_pay_fee = res.data.refund_pay_fee
								this.refundForm.refund_saler_balance = res.data.refund_saler_balance
								this.refundForm.breakfast_refund_pay_fee = res.data.breakfast_refund_pay_fee
								this.refundForm.breakfast_refund_saler_balance = res.data.breakfast_refund_saler_balance
								this.refundForm.breakfast_refund_num_show = res.data.breakfast_refund_num + '份'
								this.refundForm.breakfast_refund_num = res.data.breakfast_refund_num
								this.refundForm.breakfast_refund_balance = res.data.breakfast_refund_balance
								this.refundForm.time_romm = res.data.refund_daily_price
							} else {
								this.$message.error(res.msg)
								return
							}
						})
						.catch(() => {
							this.clearRefundCheck()
						})
				}
			})
		},
		changTimeFlag() {
			if (this.refundForm.number == '' || this.refundForm.number == null) {
				this.$message.error('请选择房间')
				this.refundForm.time_flag = '2'
				return
			}
			if (this.refundForm.number <= 0) {
				this.resetRefundInfo()
				return
			}
			;(this.$refs.refundFormRef as Form).validate((valid) => {
				if (valid) {
					checkOrder({
						id: this.refundForm.id,
						number: this.refundForm.number,
						time_flag: this.refundForm.time_flag,
						stay_ids: this.refundForm.stay_ids,
					})
						.then((res) => {
							if (res.code == 0) {
								this.refundForm.refund_balance = res.data.refund_balance
								this.refundForm.refund_integral = res.data.refund_integral
								this.refundForm.refund_pay_fee = res.data.refund_pay_fee
								this.refundForm.refund_saler_balance = res.data.refund_saler_balance
								this.refundForm.breakfast_refund_pay_fee = res.data.breakfast_refund_pay_fee
								this.refundForm.breakfast_refund_saler_balance = res.data.breakfast_refund_saler_balance
								this.refundForm.breakfast_refund_num_show = res.data.breakfast_refund_num + '份'
								this.refundForm.breakfast_refund_num = res.data.breakfast_refund_num
								this.refundForm.breakfast_refund_balance = res.data.breakfast_refund_balance
								this.refundForm.time_romm = res.data.refund_daily_price
								this.refundForm.refund_price = res.data.refund_price
							} else {
								this.$message.error(res.msg)
								return
							}
						})
						.catch(() => {
							this.clearRefundCheck()
						})
				}
			})
		},
		clearRefundCheck() {
			this.refundForm.refund_balance = 0
			this.refundForm.refund_integral = 0
			this.refundForm.refund_pay_fee = 0
			this.refundForm.refund_saler_balance = 0
			this.refundForm.breakfast_refund_pay_fee = 0
			this.refundForm.breakfast_refund_saler_balance = 0
			this.refundForm.breakfast_refund_num_show = 0 + '份'
			this.refundForm.breakfast_refund_num = 0
			this.refundForm.breakfast_refund_balance = 0
			this.refundForm.time_romm = []
		},
		closeRefundDialog() {
			this.refundForm = {
				id: '',
				number: null as any,
				room_no: '' as any,
				stay_ids: [] as any,
				time_flag: '' as any,
				break_no: '' as any,
				refund_pay_fee: '' as any,
				refund_balance: '' as any,
				refund_integral: '' as any,
				refund_saler_balance: '' as any,
				breakfast_refund_pay_fee: '' as any, // 早餐退款金额
				breakfast_refund_saler_balance: '' as any, // 返还佣金
				breakfast_refund_balance: '0' as any, //早餐退款金额
				breakfast_refund_num: '' as any,
				breakfast_refund_num_show: '' as any,
				time_romm: [] as any,
				room_number: '' as any,
				refund_price: '',
			}
			this.refund_total_pay_fee = 0
			this.breakfast_refund_total_pay_fee = 0
			this.refund_total_integral = 0
			this.refund_total_balance = 0
			this.breakfast_refund_total_balance = 0
			this.refund_total_saler_balance = 0
			this.refund_total_price = 0
			this.breakfast_refund_total_price = 0
			this.breakfast_refund_balance = 0
		},
		refundSubmit() {
			if (this.refundPlatform_id == 4) {
				if (this.refundForm.number == '') {
					this.$message.error('请选择房间数量')
					return
				}
				if (this.refundForm.room_no == '') {
					this.$message.error('请输入房间号')
					return
				}
			} else {
				if (this.refundForm.room_no == '') {
					this.$message.error('请选择房间号')
					return
				}
			}
			;(this.$refs.refundFormRef as Form).validate((valid) => {
				if (valid) {
					this.disabled = true
					refundOrder(this.refundForm).then((res) => {
						if (res.code == 0) {
							this.refundDialog = false
							this.$message({
								type: 'success',
								message: '操作成功!',
								onClose: () => {
									this.tableGetData()
								},
							})
						} else {
							this.disabled = false
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
		// 取消订单
		cancel(row) {
			this.$confirm('确定对订单号为' + row.out_trade_no + '进行取消操作吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					cancelOrder({ id: row.id }).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功!',
								onClose: () => {
									this.tableGetData()
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					})
				})
				.catch(() => {})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		getTreeName,
		edit(row) {
			;(this.$refs.formDialogRef as any).open({ id: row.id, room_id: undefined })
		},
		que() {
			this.exportLoading = true
			this.exportProgress = true
			this.exportPercentage = 0 // 重置进度
			const interval = setInterval(() => {
				console.log('Current Percentage:', this.exportPercentage) // 打印进度，确认它是否在变化
				if (this.exportPercentage < 90) {
					this.exportPercentage += 3 // 增加进度
				} else {
					clearInterval(interval) // 停止间隔
				}
			}, 300)
			setTimeout(() => {
				this.table_query.is_export = 0
			}, 500)
			this.table_query.is_export = 1
			if (this.table_query['datetimes']) {
				this.table_query['start_pay_time'] = this.table_query['datetimes'][0]
				this.table_query['end_pay_time'] = this.table_query['datetimes'][1]
			}
			if (this.table_query['staytimes']) {
				this.table_query['start_stay_time'] = this.table_query['staytimes'][0]
				this.table_query['end_stay_time'] = this.table_query['staytimes'][1]
			}
			if (this.table_query['completetimes']) {
				this.table_query['start_complete_time'] = this.table_query['completetimes'][0]
				this.table_query['end_complete_time'] = this.table_query['completetimes'][1]
			}
			if (this.table_query['canceltimes']) {
				this.table_query['start_cancel_time'] = this.table_query['canceltimes'][0]
				this.table_query['end_cancel_time'] = this.table_query['canceltimes'][1]
			}
			this.table_query['min_index'] = this.startInput - 0
			this.table_query['max_index'] = this.endInput - 0
			console.log(this.table_query)

			getOrderHotelList(this.table_query)
				.then((res) => {
					if (res.code == 1) {
						this.$message.error(res.msg + '超出数据范围请修改导出页码')
					}
					this.export_data = res.data.rows
					this.export_data.forEach((it) => {
						it.total_fee = it.total_fee / 100
						it.vip_fee = it.vip_fee / 100
						it.pay_fee = it.pay_fee / 100
						it.coupon_price = it.coupon_price / 100
						it.balance = it.balance / 100
						if (it.state == 0) {
							it.state = '待付款'
						} else if (it.state == 1) {
							it.state = '待入住'
						} else if (it.state == 2) {
							it.state = '已入住'
						} else if (it.state == 3) {
							it.state = '已完成'
						} else if (it.state == 4) {
							it.state = '已取消'
						} else {
							it.state = '-'
						}
						if (it.user) {
							if (it.user.vip_key == 0) {
								it.vip_name = '银卡会员'
							} else if (it.user.vip_key == 1) {
								it.vip_name = '金卡会员'
							} else if (it.user.vip_key == 2) {
								it.vip_name = '铂金会员'
							} else if (it.user.vip_key == 3) {
								it.vip_name = '钻石会员'
							} else if (it.user.vip_key == 4) {
								it.vip_name = '永久钻石会员'
							} else {
								it.vip_name = '-'
							}
						} else {
							it.vip_name = '-'
						}
						if (it.order_hotel_evaluation) {
							it.order_hotel_evaluation = '已点评'
						} else {
							it.order_hotel_evaluation = '未点评'
						}
						it.totalNight = it.number * it.room_number

						if (it.saler_balance) {
							it.saler_balance = it.saler_balance / 100
						} else {
							it.saler_balance = 0
						}

						let arr = ['无', '万澳会', '同行', '商旅部', '携程', '畅游西安', '小程序', '西旅小程序']
						// let typeIndex = arr.indexOf(it.platform_id)
						if (arr[it.platform_id] !== undefined) {
							it.platform_type = arr[it.platform_id]
						} else {
							it.platform_type = '无'
						}

						if (it.api_type == 1) {
							it.api_type = '绿云'
						} else if (it.api_type == 2) {
							it.api_type = 'Micro Win'
						} else {
							it.api_type = '无'
						}

						it.pay_type = it.pay_type == 1 ? '微信' : it.pay_type == 2 ? '支付宝' : it.pay_type == 3 ? 'H5支付' : '无'
						it.pay_mode = it.pay_mode == 1 ? '预付' : it.pay_mode == 2 ? '现付' : '无'

						// it.user.mobile == null ? '-' : it.user.mobile
						it.vipMobile = it.user == null ? '-' : it.user.mobile

						// 支付金额
						it.payRoomPrice = it.pay_fee ? it.pay_fee : 0
						it.payBreakPrice = it.breakfast_pay_fee ? it.breakfast_pay_fee / 100 : 0
						it.payPrice = it.all_pay_fee ? it.all_pay_fee / 100 : 0

						// 余额支付
						it.balanceRoomPrice = it.balance ? it.balance : 0
						it.balanceBreakPrice = it.breakfast_balance ? it.breakfast_balance / 100 : 0
						it.balancePrice = it.all_balance ? it.all_balance / 100 : 0
					})

					//兼容ie10哦！
					require.ensure([], () => {
						const tHeader = [
							'序号', // 31
							'订单号',
							'用户姓名',
							'入住人手机号',
							'会员手机号',
							'会员等级',
							'预定房间数量',
							'间夜数',
							'早餐数',

							'支付金额',
							'',
							'',
							// '订单总额房费',
							// '订单总额早餐费',
							// '订单总额合计',
							'余额支付',
							'',
							'',
							// '实付金额房费',
							// '实付金额早餐费',
							// '实付金额合计',

							'订单状态',
							'入住日期',
							'离店日期',
							'到店时间',
							'完成时间',
							'取消时间',
							'订单类型',
							'支付方式',
							'接口平台',
							'市场类型',
							// '余额支付',
							'佣金支付',
							'积分抵扣',
							'抵扣券',
							'赠送积分',
							'支付时间',
							'点评状态',
						] //将对应的属性名转换成中文
						const tHeader2 = [
							'', // 31
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'房费',
							'早餐费',
							'合计',
							'房费',
							'早餐费',
							'合计',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
							'',
						] //将对应的属性名转换成中文
						const filterVal = [
							'id',
							'out_trade_no',
							'user_names',
							'mobile',
							'vipMobile', // 会员手机号
							// 'user.mobile', // 会员手机号
							'vip_name',
							'room_number',
							'totalNight',
							'breakfast_num',

							'payRoomPrice',
							'payBreakPrice',
							'payPrice',
							// 余额支付
							'balanceRoomPrice',
							'balanceBreakPrice',
							'balancePrice',

							'state',
							'start_date',
							'end_date',
							'stay_time',
							'complete_time',

							'cancel_time',
							'pay_mode', // 订单类型
							'pay_type', // 支付方式
							'api_type', // 接口平台
							'platform_type', // + '市场类型'
							// 'balance',
							'all_saler_balance', // + 佣金支付
							'integral',
							'coupon_price',
							'all_give_integral',
							'pay_time',
							'order_hotel_evaluation',
						] //table表格中对应的属性名
						const list = this.export_data

						// let arr: Array<any> = []
						// for (let index = 0; index < 32; index++) {
						// 	const element = ''
						// 	arr[index] = element
						// }
						// const multiHeader = arr
						const data = this.formatJson(filterVal, list)
						this.is_export = 0

						const merges = [
							'A1:A2',
							'B1:B2',
							'C1:C2',
							'D1:D2',
							'E1:E2',
							'F1:F2',
							'G1:G2',
							'H1:H2',
							'I1:I2',
							'P1:P2',
							'Q1:Q2',
							'R1:R2',
							'S1:S2',
							'T1:T2',
							'U1:U2',
							'V1:V2',
							'W1:W2',
							'X1:X2',
							'Y1:Y2',
							'Z1:Z2',
							'AA1:AA2',
							'AB1:AB2',
							'AC1:AC2',
							'AD1:AD2',
							'AE1:AE2',
							'J1:L1',
							'M1:O1',
						]

						// multiHeader = [], // 第一行表头
						// multiHeader2 = [], // 第二行表头
						// data,
						// filename, //文件名
						// merges = [], // 合并
						// autoWidth = true,
						// bookType = 'xlsx',
						// diagonal = [], //斜线
						// fontStyle = false

						export_json_to_excelPro({
							multiHeader2: tHeader,
							multiHeader: tHeader2,
							// tHeader,
							data,
							filename: '订房订单',
							autoWidth: false,
							merges,
						})
						this.exportLoading = false
						// 请求完成后更新进度为100%
						this.exportPercentage = 100
						clearInterval(interval) // 停止间隔
						this.$message.success('导出完成！')
						setTimeout(() => {
							this.exportProgress = false
						}, 3000)
						// export_json_to_excel(tHeader, data, '订房订单')
					})
				})
				.catch((err) => {
					this.exportPercentage = 0
					this.exportProgress = false
					this.exportLoading = false
					clearInterval(interval) // 停止间隔
				})
			if (this.table_query['datetimes']) {
				this.table_query['start_pay_time'] = ''
				this.table_query['end_pay_time'] = ''
			}
			if (this.table_query['staytimes']) {
				this.table_query['start_stay_time'] = ''
				this.table_query['end_stay_time'] = ''
			}
			if (this.table_query['completetimes']) {
				this.table_query['start_complete_time'] = ''
				this.table_query['end_complete_time'] = ''
			}
			if (this.table_query['canceltimes']) {
				this.table_query['start_cancel_time'] = ''
				this.table_query['end_cancel_time'] = ''
			}
		},
		// 确定导出数据
		handleExportData() {
			this.que()
		},
		// 计算导出
		inportexcel() {
			this.endInput = Math.ceil(this.table_state.total / this.table_query.limit)
			// 起始页最大值
			this.startmaxValue = this.endInput
			this.endmaxValue = this.endInput
			this.dialogVisible = true
		},
	},
	// created() {
	// 	this.tableGetData()
	// },
	computed: {
		...mapState<any>('order', {
			hotelOrderState: (state) => state.hotelOrderState,
		}),
		returnPayMode() {
			return (this as any).refundDialogBaseInfo.pay_type == 1
				? '微信'
				: (this as any).refundDialogBaseInfo.pay_type == 2
				? '支付宝'
				: (this as any).refundDialogBaseInfo.pay_type == 3
				? 'H5支付'
				: '其他'
		},
		// 合并计算导出总条数和是否禁用导出按钮的逻辑
		exportDisabled(): boolean {
			// 如果 startInput 或 endInput 为 0，或者为空，或者 endInput 小于 startInput，则禁用导出
			if (
				!this.startInput ||
				!this.endInput ||
				Number(this.startInput) === 0 ||
				Number(this.endInput) === 0 ||
				Number(this.endInput) < Number(this.startInput)
			) {
				return true
			}
			const totalExportDataCount = (Number(this.endInput) - Number(this.startInput)) * this.table_query.limit
			return totalExportDataCount > 10000
		},
		// 计算支付合计
		totalAmount(): string {
			const sumPayFee = (Number(this.table_day.sum_pay_fee) || 0) / 100
			const sumBalance = (Number(this.table_day.sum_balance) || 0) / 100
			const sumIntegral = (Number(this.table_day.sum_integral) || 0) / 50
			const sumSalerBalance = (Number(this.table_day.sum_saler_balance) || 0) / 100

			const total = sumPayFee + sumBalance + sumIntegral + sumSalerBalance

			if (total === 0) {
				return '0' // 如果 total 为 0，直接返回 '0'
			}

			// 判断是否是整数，若是整数则返回整数，否则保留两位小数
			return Number.isInteger(total) ? total.toString() : total.toFixed(2)
		},
		totalRefundAmount(): string {
			const sumRefundPayFee = (Number(this.table_day.sum_refund_total_pay_fee) || 0) / 100
			const sumRefundBalance = (Number(this.table_day.sum_refund_total_balance) || 0) / 100
			const sumRefundIntegral = (Number(this.table_day.sum_refund_total_integral) || 0) / 50
			const sumRefundSalerBalance = (Number(this.table_day.sum_refund_total_saler_balance) || 0) / 100

			const totalRefund = sumRefundPayFee + sumRefundBalance + sumRefundIntegral + sumRefundSalerBalance

			if (totalRefund === 0) {
				return '0' // 如果 totalRefund 为 0，直接返回 '0'
			}

			// 判断是否是整数，若是整数则返回整数，否则保留两位小数
			return Number.isInteger(totalRefund) ? totalRefund.toString() : totalRefund.toFixed(2)
		},
	},
})
