import request, { ResponsePageableData } from '@/libs/request'

/** 获取店铺列表 */
export const getStoreListWithFilters = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/getStoreListWithFilters', data })

/** 订单列表 */
export const getOrderListWithFilters = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/getOrderListWithFilters', data })

/** 获取分类列表 */
export const getCategoryList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/getCategoryList', data })

/** 设置店铺上下架 */
export const setStoreStatus = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/setStoreStatus', data })

/** 设置折扣 */
export const setStoreDiscount = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/setStoreDiscount', data })

/** 设置佣金 */
export const setStoreCommission = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/setStoreCommission', data })

/** 获取结算报表列表 */
export const storeAccountIndex = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/index', data })

/** 结算报表确认结算 */
export const confirmstoreAccountIndex = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/payment', data })

/** 结算报表详情 */
export const storeAccountDetail = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/detail', data })

/** 结算报表上传发票 */
export const upLoadInvoice = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/invoice', data })

/** 结算报表上传发票 */
export const exportInvoice = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/export', data })

/** 店铺审核 */
export const auditStore = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/auditStore', data })

/** 店铺入驻 */
export const addStore = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/addStore', data })

// 编辑店铺
export const editStore = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/editStore', data })

// 账单详情
export const billDetailList = (data?) => request<ResponsePageableData>({ url: '/adminapi/StoreAccount/order', data })

// 分类列表
export const getCategoryListData = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/getCategoryListData', data })

// 添加分类
export const addCategory = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/addCategory', data })

// 更新分类
export const updateCategory = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/updateCategory', data })

// 删除分类
export const deleteCategory = (data?) => request<ResponsePageableData>({ url: '/adminapi/Store/deleteCategory', data })
